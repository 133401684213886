import React from "react"

import GlitchText from 'react-glitch-effect/core/GlitchText'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  header: {
    margin: '100px 0px 100px 0px'
  }
}));

const Heading = ({title}) => {
  const classes = useStyles();

  return (
    <Typography variant='h4' component='h2'
      className={classes.header}
    >
      <GlitchText>
        {title}
      </GlitchText>
    </Typography>
  )
}

export const HeadingBase = ({title, children}) => {
  const classes = useStyles();

  return (
    <>
    <Typography variant='h4' component='h2'>
      <GlitchText>
        {title}
      </GlitchText>
    </Typography>
    {children}
    </>
  )
}

export default Heading
