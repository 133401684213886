import React from "react"

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from "@material-ui/core/Typography"

import { LinkTab } from "../../components/Link"
import Paper from "../../components/Paper"
import Header from "../Header"


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '10px 0px 10px 0px',
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.light,
    borderWidth: '1px',
  },
  footerItem:{
    textAlign: 'left',
    // fontWeight: '400'
  }
}));

const IndexPage = ({children}) => {
  const classes = useStyles();
  return (
    <Container maxWidth='lg'>
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.header}>
          <Paper>
            <Header />
          </Paper>
        </Grid>
        {children}
        <Grid container >
        <Grid item xs={6} md={3}>
          <Paper>
          <Typography variant='body2' component='h6'
            className={classes.footerItem}
          >
            2021 © SKLL Chain <br />
            Rotterdam, Netherlands
          </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} md={3}>
          <Paper>
          <Typography variant='body2' component='h6'
            className={classes.footerItem}
          >
            KVK: <br />
            81675976
          </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} md={3}>
          <Paper>
          <Typography variant='body2' component='h6'
            className={classes.footerItem}
          >
            NL BTW: NL003589838B97<br />
            GB VAT: 380841783
          </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} md={3}>
          <Paper>
          <Typography variant='body2' component='h6' 
            className={classes.footerItem}
          >
            IG:<br />
            <LinkTab href='https://instagram.com/skllchain'
              color='inherit'
            >
              @skllchain
            </LinkTab>
          </Typography>
          </Paper>
        </Grid>
        </Grid>
      </Grid>
    </div>
    </Container>
  )
}

export default IndexPage
