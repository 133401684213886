import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: 'transparent',
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.light,
    borderWidth: '1px 0px 0px 0px',
    color: '#fff'
  },
  paperClean: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: 'transparent',
    // borderStyle: 'solid',
    // borderColor: '#fff',
    // borderWidth: '0.5px 0.5px 0.5px 0.5px',
    color: '#fff'
  },
}));


const PaperBase = ({children}) => {
  const classes = useStyles();
  return (
    <Paper square className={classes.paper} elevation={0}>
        {children}
    </Paper>
  )
}

export const PaperClean = ({children}) => {
  const classes = useStyles();
  return (
    <Paper square className={classes.paperClean} elevation={0}>
        {children}
    </Paper>
  )
}

export default PaperBase
