import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from "@material-ui/core/Typography"

import { LinkIn } from '../../components/Link'
import Avatar from "../../components/Avatar"

import { useAuth } from "../../context"

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  menu: {
    margin: 'auto 0px 0px 0px',
  },
  title: {
    margin: 'auto 0px auto auto',
    fontSize: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px'
    },
    fontWeight: '700',
  },
  username: {
    display: 'inline',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  }
}));

const Component = () => {
  const classes = useStyles()
  const { user, profile, logout, token } = useAuth()

  return (
    <div className={classes.root}>
      <Typography variant='body2' component='p'
        className={classes.menu}
      >
        {user ? (
          <>
          <div className={classes.username}>
            {user.email}{' | '}
          </div>
          <LinkIn
            to="/console" 
            onClick={() => logout()}
            color='inherit'
          >
            logout
          </LinkIn>
          { (user
              && user.emailVerified
              && profile
              && profile.is_setup
              && profile.is_active
              && !profile.is_blocked
          ) && (
            <>
            {' | '}
            <LinkIn
              to='/console/settings'
              color='inherit'
            >
              profile
            </LinkIn>
            </>
          )}
          </>
        ) : (
          <>
          <LinkIn
            to='/console/login'
            color='inherit'
          >
            login
          </LinkIn>
          {' | '}
          <LinkIn
            to='/console/register'
            color='inherit'
          >
            register
          </LinkIn>
        </>
        )}
      </Typography>

      <Typography variant='h3' component='h1'
        className={classes.title}
      >
        <LinkIn to='/'
          color='inherit'
        >
          SKLLCHAIN
        </LinkIn>
      </Typography>

      <LinkIn to='/console'>
        <Avatar />
      </LinkIn>
    </div>
  )
}

export default Component