import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from "@material-ui/core/Avatar"

import logo1 from '../../assets/images/skulls/skull-01.png'
import logo2 from '../../assets/images/skulls/skull-02.png'
import logo3 from '../../assets/images/skulls/skull-03.png'
import logo4 from '../../assets/images/skulls/skull-04.png'
import logo5 from '../../assets/images/skulls/skull-05.png'
import logo6 from '../../assets/images/skulls/skull-06.png'
import logo7 from '../../assets/images/skulls/skull-07.png'
import logo8 from '../../assets/images/skulls/skull-08.png'
import logo9 from '../../assets/images/skulls/skull-09.png'
import logo10 from '../../assets/images/skulls/skull-10.png'
import logo11 from '../../assets/images/skulls/skull-11.png'
import logo12 from '../../assets/images/skulls/skull-12.png'
import carbonBg from '../../assets/images/carbon.png'
import GlitchClip from 'react-glitch-effect/core/GlitchClip'

const logos = [
  logo1, logo2, logo3, logo4,
  logo5, logo6, logo7, logo8,
  logo9, logo10, logo11, logo12
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    
  },
  avatar:{
    marginLeft: 20,
    width: '50px',
    height: '50px',
    borderStyle: 'solid',
    borderColor: '#fff',
    borderWidth: '4px 2px 4px 2px',
    background: `url(${carbonBg}) repeat left top`,
  },
}));


const Component = () => {
  const classes = useStyles();
  const [logo, setLogo] = useState(logos[4])
  
  useEffect(() => {
    const interval = setInterval(() => {
      setLogo(logos[Math.floor(Math.random() * logos.length)]);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <GlitchClip>
      <Avatar className={classes.avatar}
        src={logo} alt='SKLL CHAIN Logo'
      />
    </GlitchClip>
  )
}

export default Component